import React from 'react'
import Layout from '../../components/Layout'
import {Location} from '@reach/router'
import Content from '../../components/Content'
import contentImage from '../../images/skybell-and-app (1).jpg'
export default () => (  
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                 <Content>
                 <h2>
                     Manage Access To Your Property
                 </h2>
                <p>
                    Control who has access to your property, and when, with integrated control for door locks and garage doors.
                </p>

                <h2>
                    Lock Codes
                </h2>
                <p>
                    Create unique lock codes for each user, and set a schedule to control when the codes can be used. You can limit access by day and time. You can create and disable codes at any time, so there’s no need to rekey or change your locks.
                </p>
                <div className="row center">
                    <img src={contentImage} style={{maxWidth:'350px'}}/>
                </div>
                <h2>
                    Garage Doors
                </h2>
                <p>
                    Garage doors are a key entry point to a property. Now you can remotely open or close the garage door and get a notification if the door is left open when it shouldn’t be.
                </p>

                <h2>
                    Reduce False Alarms
                </h2>
                <p>
                    Set your security system to automatically disarm when a valid lock code is entered.This is simpler for users, who only have to remember one lock code (not a system code), avoiding the hassle of false alarms.
                </p>

                <h2>
                    Notifications
                </h2>
                <p>
                    Never forget to lock the door or close the garage again. Get reminders if a door is left unlocked, and see who just unlocked or locked a door.
                </p>

                <h2>
                    Remote Control
                </h2>
                <p>
                    Remotely lock or unlock the door right from your app. You can let in a visitor or a contractor without having to be there.
                </p>
                 </Content>
                </Layout>
            )
        }
    </Location>
)